let sliders = document.querySelectorAll('[data-slider]');

sliders.forEach(slider => {
    let current = 1;
    let slides = slider.querySelectorAll('[data-slide]');
    let slide_toggles = slider.querySelectorAll('[data-goto-slide]');

    slide_toggles.forEach(slide_toggle => {
        slide_toggle.addEventListener('click', e => {
            let index = slide_toggle.dataset.gotoSlide;
    
            if (index == 'prev') {
                index = parseInt(current) - 1;
            } else if (index == 'next') {
                index = parseInt(current) + 1;
            }
    
            if (index < 1) {
                index = slides.length;
            } else if (index > slides.length) {
                index = 1;
            }
    
            current = index;
            
            let slide = slider.querySelector('[data-slide="' + current + '"]');
            let toggles = slider.querySelectorAll('[data-goto-slide="' + current + '"]');

            e.preventDefault();

            slides.forEach(slide => {
                slide.classList.remove('active');
            });

            slide_toggles.forEach(slide_toggle => {
                slide_toggle.classList.remove('active');
            });

            slide.classList.add('active');

            toggles.forEach(toggle => {
                toggle.classList.add('active');
            });
        });
    });
});