import Vue from '../../../node_modules/vue/dist/vue.common.js';

const API_DOMAIN = window.wpApiSettings && window.wpApiSettings.api_domain ? window.wpApiSettings.api_domain : 'https://riversandinc.com';
const NONCE = window.wpApiSettings && window.wpApiSettings.nonce ? window.wpApiSettings.nonce : null;

const values = {
    product_types: {
        'Sand': [
            {name: 'River Sand', type: 'a'},
            {name: 'Construction Sand', type: 'a'},
            {name: 'Masonry Sand', type: 'a'},
            {name: 'M10 Sand (Granite Sand/Dust)', type: 'a'},
            {name: 'Arena Sand and Horse Footing', type: 'a'},
            {name: 'Bunker Sand', type: 'a'},
            {name: 'Divot Mix', type: 'a'},
            {name: 'Topdressing', type: 'a'},
            {name: 'Ultradwarf Topdressing Sand', type: 'a'}
        ],
        'Soil': [
            {name: 'Topsoil', type: 'a'},
            {name: 'Bioretention Soil Mix', type: 'a'},
            {name: 'Engineered Soil Mix', type: 'a'},
            {name: 'Bioswale Soil Mix', type: 'a'},
            {name: 'Landscaper\'s Soil Mix', type: 'a'},
            {name: 'Potting Soil', type: 'a'},
            {name: 'Compost', type: 'b'},
            {name: 'Soil Conditioner', type: 'a'},
            {name: 'Green Roof Soil Mix', type: 'a'},
            {name: 'CU-Structural Soil', type: 'a'}
        ],
        'Dirt': [
            {name: 'Fill Dirt', type: 'a'}
        ],
        'Gravel': [
            {name: 'River Rock', type: 'a'},
            {name: 'Pea Gravel', type: 'a'},
            {name: 'Gravel', type: 'a'}
        ],
        'Mulch': [
            {name: 'Mulch', type: 'b'}
        ],
        'Baseball Field': [
            {name: 'AMP Infield Mix', type: null},
            {name: 'AMP Mound Mix (Mound Clay)', type: null}
        ]
    },
    field_sizes: {
        'Baseball - Skinned Infield - 90\' base path - 95\' infield arc': 18300,
        'Baseball - Grass Infield - 90\' base path - 95\' infield arc': 11550,
        'Baseball - Skinned Infield - 80\' base path - 80\' infield arc': 13650,
        'Baseball - Grass Infield - 80\' base path - 80\' infield arc': 8400,
        'Baseball - Skinned Infield - 70\' base path - 70\' infield arc': 10700,
        'Baseball - Grassed Infield - 70\' base path - 70\' infield arc': 6800,
        'Baseball - Skinned Infield - 60\' base path - 50\' infield arc': 6700,
        'Baseball - Grassed Infield - 60\' base path - 50\' infield arc': 3850,
        'Softball - 65\'base path - 65\' infield arc': 9300,
        'Softball - 60\'base path - 60\' infield arc': 8350,
        'Softball - 55\'base path - 55\' infield arc': 6100
    },
    area_types: [
        'Rectangle/Square',
        'Circle/Elipse',
        'Baseball/Softball Field'
    ],
    units: [
        'Ft',
        'In',
        'Yd'
    ]
};

const product_type_redirects = {
    'AMP Infield Mix': '/dirt/baseball-infield-dirt/#baseball-calculator',
    'AMP Mound Mix (Mound Clay)': '/dirt/baseball-clay/#baseball-calculator'
};

const area_type_redirects = {
    'Baseball/Softball Field': '/dirt/baseball-infield-dirt/#baseball-calculator'
};

const initial_values = {
    product_type: '',
    area_type: '',
    length: '',
    length_unit: 'Ft',
    width: '',
    width_unit: 'Ft',
    depth: '',
    depth_unit: 'In',
    diameter: '',
    diameter_unit: 'Ft',
    result: null
};

const get_quote_fields = {
    product_type: '5',
    area_type: '6',
    length: '7',
    length_unit: '12',
    width: '8',
    width_unit: '13',
    depth: '10',
    depth_unit: '15',
    diameter: '9',
    diameter_unit: '14',
    result: '11'
};

const conversions = {
    'In': 0.083333333333,
    'Ft': 1,
    'Yd': 3
}

const dense_material_adjustment = 1.35;

let Calculator = function(elId, calculator_type = 'normal') {
    if(!document.getElementById(elId)) {
        return null;
    }

    return new Vue({
        el: `#${elId}`,
        data: {
            elId: elId,
            calculator_type: calculator_type,
            is_loaded: false,
            values: values,
            entry: {...initial_values},
            validator: null,
            errors: [],
            mode: 'calculate'
        },

        mounted: function() {
            this.is_loaded = true;

            if(this.calculator_type === 'baseball') {
                this.entry.length_unit = '';
                this.entry.width_unit = '';
                this.entry.diameter_unit = '';
            }
        },

        methods: {
            resetEntry() {
                this.entry =  {...initial_values}
                this.errors = [];
                this.mode = 'calculate';
                this.setGetQuoteFormVisibility(false);
            },

            resetResult() {
                this.entry = Object.assign({}, this.entry, {
                    result: initial_values.result
                });
            },

            getResult(e) {
                e && e.preventDefault();

                this.validateResult();

                if(this.errors.length) {
                    return;
                }
                
                if(this.calculator_type === 'baseball') {
                    this.calculateBaseball();
                }
                else if(this.entry.area_type === 'Rectangle/Square') {
                    this.calculateSquare();
                }
                else {
                    this.calculateCircle();
                }

                this.setHiddenFormFieldValues();

                this.mode = 'result';
                this.setGetQuoteFormVisibility(false);
            },

            getProductType() {
                let type = 'a';

                Object.keys(values.product_types).forEach((key) => {
                    values.product_types[key].forEach((product) => {
                        if(this.entry.product_type === product.name) {
                            type = product.type;
                        }
                    });
                });

                return type;
            },

            getQuote(e) {
                e && e.preventDefault();

                this.mode = 'quote';
                this.setGetQuoteFormVisibility(true);
            },

            onProductTypeChange() {
                if(this.entry.product_type && product_type_redirects[this.entry.product_type]) {
                    window.location = product_type_redirects[this.entry.product_type];
                }

                this.resetResult();
            },

            onAreaTypeChange() {
                if(this.entry.area_type && area_type_redirects[this.entry.area_type]) {
                    window.location = area_type_redirects[this.entry.area_type];
                }

                this.resetResult();
            },

            calculateBaseball() {
                let multiplier = this.values.field_sizes[this.entry.product_type];
                let foot_depth = this.entry.depth * conversions[this.entry.depth_unit];

                if(typeof multiplier !== 'number') {
                    return;
                }

                let volume = multiplier * foot_depth;
                let tonnage = (volume / 27) * ((1.35) * (1.15));
                this.entry.result = tonnage.toFixed(2);
            },

            calculateCircle() {
                let diameter = parseFloat(this.entry.diameter) * conversions[this.entry.diameter_unit];
                let depth = parseFloat(this.entry.depth) * conversions[this.entry.depth_unit];

                if(isNaN(diameter) || isNaN(depth)) {
                    return;
                }

                let pi = Math.PI;
                let radia = diameter / 2;
                let tarea = pi * radia * radia;
                let precy = tarea * depth;
                let tcy = precy / 27;

                let tonnage = tcy * (this.getProductType() === 'a' ? dense_material_adjustment : 1);
                this.entry.result = tonnage.toFixed(2);
            },

            calculateSquare() {
                let length = parseFloat(this.entry.length) * conversions[this.entry.length_unit];
                let width = parseFloat(this.entry.width) * conversions[this.entry.width_unit];
                let depth = parseFloat(this.entry.depth) * conversions[this.entry.depth_unit];

                if(isNaN(length) || isNaN(width) || isNaN(depth)) {
                    return;
                }

                let volume = length * width * depth;
                let calcyards = volume / 27;

                let tonnage = calcyards * (this.getProductType() === 'a' ? dense_material_adjustment : 1);
                this.entry.result = tonnage.toFixed(2);
            },

            getResultLabel() {
                return (this.getProductType() === 'b') ? 'Cubic Yards' : 'Tons';
            },

            setGetQuoteFormVisibility(is_visible = true) {
                let get_quote_form = document.querySelector(`[data-app-calculator="#${this.elId}"]`);
                if(!get_quote_form) {
                    return;
                }

                if(is_visible) {
                    get_quote_form.classList.add('app-get-quote-loaded');
                }
                else {
                    get_quote_form.classList.remove('app-get-quote-loaded');
                }
            },

            setHiddenFormFieldValues() {
                let get_quote_form = document.querySelector(`[data-app-calculator="#${this.elId}"]`);

                if(!get_quote_form) {
                    return;
                }

                Object.keys(get_quote_fields).forEach((fieldname) => {
                    let field = get_quote_form.querySelector(`[name="input_${get_quote_fields[fieldname]}"]`);
                    if(field) {
                        field.value = this.entry[fieldname];
                    }
                });
            },

            validateResult() {
                let new_errors = [];

                this.errors = [];

                if(!this.entry.product_type.length) {
                    new_errors.push('product_type');
                }

                if(!this.entry.depth.length) {
                    new_errors.push('depth');
                }

                if(this.calculator_type === 'normal') {
                    if(!this.entry.area_type.length) {
                        new_errors.push('area_type');
                    }

                    if(!this.entry.depth_unit.length) {
                        new_errors.push('depth_unit');
                    }

                    if(this.entry.area_type === 'Rectangle/Square') {
                        if(!this.entry.width.length) {
                            new_errors.push('width');
                        }
        
                        if(!this.entry.width_unit.length) {
                            new_errors.push('width_unit');
                        }
    
                        if(!this.entry.length.length) {
                            new_errors.push('length');
                        }
        
                        if(!this.entry.length_unit.length) {
                            new_errors.push('length_unit');
                        }
                        
                    }
                    else if(this.entry.area_type === 'Circle/Elipse') {
                        if(!this.entry.diameter.length) {
                            new_errors.push('diameter');
                        }
        
                        if(!this.entry.diameter_unit.length) {
                            new_errors.push('diameter_unit');
                        }
                    }
                }

                this.errors = new_errors;
            }
        }
    });
};

export default Calculator;