import AtdHiddenHeight from './AtdHiddenHeight';

class Expandset {
    constructor(el = null, options = {}) {
        if(!el) {
            return;
        }

        this.expandset_el = el;

        // make sure we have an id for this expandset
        this.expandset_el.id = this.expandset_el.id || `atd-expandset-${Expandset.expandsets.length + 1}`;

        this.options = Object.assign({
            transition_time: 300,
            accordion: false,
            keep_open: typeof this.expandset_el.dataset.keepOpen !== 'undefined' ? true : false
        }, options);

        this.toggleExpandsetListener = (e) => {
            if(e.target.dataset.toggleExpandset) {
                e.preventDefault();
                window.setTimeout(() => {
                    this.toggleExpandset(e.target.dataset.toggleExpandset);
                }, 0);
                
                return;
            }
        }

        this.setEventListeners();

        Expandset.expandsets.push(this);
    }

    toggleExpandset(expand_id) {

        let expand_bodies = this.expandset_el.querySelectorAll(`[data-expandset-body="${expand_id}"]`);
        let expand_toggles = document.querySelectorAll(`[data-toggle-expandset="${expand_id}"]`);

        if(!expand_bodies) {
            return false;
        }

        expand_bodies.forEach((expand_body) => {
            let fullHeight = AtdHiddenHeight(expand_body);

            if(this.options.keep_open && expand_body.classList.contains('open')) {
                return;
            }

            expand_body.style.height = `0px`;

            if(this.options.accordion) {
                this.closeAll(expand_body);
            }

            expand_body.classList.add('transitioning');

            window.setTimeout(() => {
                expand_body.classList.toggle('open');
                if(expand_body.classList.contains('open')) {
                    expand_body.style.height = `${fullHeight}px`;
                }
                else {
                    expand_body.style.height = `0px`;
                }

                window.setTimeout(() => {
                    expand_body.classList.remove('transitioning');
                }, this.options.transition_time);
            }, 0);
        });
        
        expand_toggles.forEach(toggle => {
            if((this.options.keep_open && toggle.classList.contains('open')) || typeof toggle.dataset.toggleExpandsetNoStyle !== 'undefined') {
                return;
            }

            toggle.classList.toggle("open");
        });
    }

    closeExpandset(expand) {
        if(!expand) {
            return false;
        }

        let expand_toggles = document.querySelectorAll(`[data-toggle-expandset="${expand.dataset.expandsetBody}"]`);

        expand_toggles.forEach(toggle => {
            if(typeof toggle.dataset.toggleExpandsetNoStyle === 'undefined') {
                toggle.classList.remove('open');
            }
        });

        expand.classList.add('transitioning');

        window.setTimeout(() => {
            expand.classList.remove('open');
            expand.style.height = `0px`;

            this.expandset_el.querySelectorAll(`[data-toggle-expandset="${expand.dataset.expandsetBody}"]`).forEach((toggle) => {
                if(typeof toggle.dataset.toggleExpandsetNoStyle === 'undefined') {
                    toggle.classList.remove('open');
                }
            });
            expand_toggles.forEach((toggle) => {
                if(typeof toggle.dataset.toggleExpandsetNoStyle === 'undefined') {
                    toggle.classList.remove('open');
                }
            });

            window.setTimeout(() => {
                expand.classList.remove('transitioning');
            }, this.options.transition_time);
        }, 0);
    }

    closeAll(except) {
        let expands = this.expandset_el.querySelectorAll('[data-expandset-body]');
        expands.forEach((expand) => {
            if(expand.classList.contains('open') && expand.dataset.expandsetBody !== except.dataset.expandsetBody) {
                this.closeExpandset(expand);
            }
        });
    }

    setEventListeners() {

        document.body.addEventListener('click', this.toggleExpandsetListener);
    }

    static getExpandsetById(id) {
        let match = false;

        Expandset.expandsets.forEach((expandset) => {
            if(!match) {
                match = expandset.expandset_el.id === id ? expandset : false;
            }
        });

        return match;
    }
}

Object.defineProperty(Expandset, 'expandsets', {
    get: function() { return this.hasOwnProperty('_expandsets') ? this._expandsets : void 0; },
    set: function(v) { this._expandsets = v; }
});

Expandset.expandsets = [];

export default Expandset;