import Calculator from './components/Calculator';
import Expandset from './lib/Expandset';
import PageHeader from './components/PageHeader';

import './lib/BodyPadding';
import './lib/LazyloadVideo';
import './components/InteriorHero';
import './components/PageHero';
import './components/CircleSlider';
import './components/Products';

window.addEventListener('DOMContentLoaded', () => {
    // Page Header
    let options = {
        container: document.querySelector('[page]') || document.querySelector('html'),
        el: document.querySelector('[page-header]'),
        toggle_el: document.querySelector('[page-header-hamburger]'),
        menu_item_els: document.querySelectorAll('[page-header-menu-item]')
    }
    
    if (options.el) {
        let page_header = new PageHeader(options);
    }

    // Expandsets
    let expandsets = document.querySelectorAll('[data-expandset]');
    expandsets.forEach(expandset => {
        new Expandset(expandset, {
            accordion: true
        });
    });

    //init caclulator apps
    let calculator = new Calculator('calculator-app');
    let baseball_calculator = new Calculator('baseball-calculator-app', 'baseball');
});