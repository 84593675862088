import Glide from '@glidejs/glide';

let sliders = document.querySelectorAll('[data-glide]');

if (sliders.length) {
    sliders.forEach(slider => {
        new Glide(slider, {
            type: 'carousel',
            perView: 4,
            breakpoints: {
                1200: {
                    perView: 3
                },
                768: {
                    perView: 2
                },
                640: {
                    perView: 1,
                    peek: 120
                },
                480: {
                    perView: 1,
                    peek: 80
                },
                400: {
                    perView: 1,
                    peek: 60
                }
            }
        }).mount();
    });
}