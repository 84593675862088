class PageHeader {
    constructor(options) {
        this.state = {
            is_visible: true,
            is_open: false,
            last_scroll: window.pageYOffset
        }

        this.options = options;
        this.options.html = document.querySelector('html');
        this.options.body = document.querySelector('body');

        window.requestAnimationFrame(() => { this.checkScroll(); });
        this.options.toggle_el.addEventListener('click', () => { this.toggleMenu(); });

        this.options.menu_item_els.forEach((el) => {
            el.addEventListener('click', (e) => { this.toggleMenuItem(e); });
        });
    }

    checkScroll() {
        let scrolling_down = false;

        if (window.pageYOffset > 80 && window.pageYOffset > this.state.last_scroll) {
            scrolling_down = true;
        }

        if (window.pageYOffset > 80) {
            this.options.el.classList.add('is-scrolled');
        } else {
            this.options.el.classList.remove('is-scrolled');
        }

        if (window.pageYOffset !== this.state.last_scroll) {
            this.state.is_visible = !scrolling_down;
            this.state.last_scroll = window.pageYOffset;

            if (this.state.is_visible) {
                this.options.el.classList.add('is-visible');
                this.options.body.classList.add('has-visible-header');
            } else {
                this.options.el.classList.remove('is-visible');
                this.options.body.classList.remove('has-visible-header');
            }
        }

        window.requestAnimationFrame(() => { this.checkScroll(); });
    }

    toggleMenu() {
        this.state.is_open = !this.state.is_open;
        this.options.el.classList.toggle('is-open', this.state.is_open);
        this.options.html.classList.toggle('prevent-scroll', this.state.is_open);
        this.options.toggle_el.classList.toggle('is-active', this.state.is_open);
    }

    toggleMenuItem(e) {
        if (window.innerWidth < 1040) {
            if (e.target.classList.contains('button-content')) {
                return;
            }
            
            e.preventDefault();
            e.target.closest('li').classList.toggle('is-open');
        }
    }
}

export default PageHeader;