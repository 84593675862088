let medallion_toggles = document.querySelectorAll('[data-toggle="medallions"]');

medallion_toggles.forEach(medallion_toggle => {
    medallion_toggle.addEventListener('click', e => {
        e.preventDefault();

        document.querySelectorAll('.hidden-medallion').forEach(medallion => {
            medallion.classList.toggle('is-hidden');
        });
    });
});